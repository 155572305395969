<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">冻结详情</span>
        <el-button class="goList" type="primary" @click="goBack">返回</el-button>
      </div>
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="订单编号：">
          <div>{{ orderId }}</div>
        </el-form-item>
        <el-form-item label="订单明细：">
          <el-table
            :data="detail.detailRespDtoList"
            border
            :summary-method="getSummaries"
            show-summary
            style="width:408px"
          >
            <el-table-column prop="productName" label="类目"> </el-table-column>
            <el-table-column prop="amount" label="单价"> </el-table-column>
            <el-table-column prop="productCount" label="数量"> </el-table-column>
            <el-table-column prop="productSumAmount" label="总价"> </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="订单创建时间：">
          <div>{{ detail.showCreateTime }}</div>
        </el-form-item>
        <el-form-item label="订单支付时间：">
          <div>{{ detail.showPayTime }}</div>
        </el-form-item>
        <el-form-item label="订单金额：">
          <div>{{ detail.orderAmount }}元</div>
        </el-form-item>
        <el-form-item label="抽成比例：">
          <div>{{ detail.showCommissionRate }}</div>
        </el-form-item>
        <el-form-item label="订单实际收益：">
          <div>{{ detail.orgIncome }}元</div>
        </el-form-item>
        <el-form-item label="结算状态：">
          <div>{{ detail.showEntryStatus }}</div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import to from 'await-to'
import { forzenInfoDetail } from '@/api/finance'
export default {
  data() {
    return {
      form: {},
      detail: {},
      orderId: this.$route.query.orderId,
    }
  },
  created() {
    this.forzenInfoDetailData()
  },
  methods: {
    async forzenInfoDetailData() {
      const [res, err] = await to(forzenInfoDetail({ orderId: this.orderId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.detail = res.data.data
    },
    goBack() {
      this.$router.push('frozenDetail')
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      //   console.log(columns, data)
      var price = 0
      data.map(v => {
        price += Number(v.productSumAmount)
      })
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计'
          return
        }
        if (index === columns.length - 1) {
          sums[index] = price + '元'
          return
        }
      })

      return sums
    },
  },
}
</script>

<style scoped lang="scss">
.goList {
  width: 56px;
  height: 30px;
  margin-left: 20px;
}
.statusBtn {
  margin-left: 200px;
}
</style>
